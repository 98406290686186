export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.front__diensten__slider').slick({
            centerMode: false,
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            cssEase: 'linear',
            infinite: true,
            swipe: true,
            draggable: true,
            swipeToSlide: true,
            nextArrow: $('.front__diensten .next__slide__button'),
        });

        $('.downloads__filter .searchandfilter > ul > li li').addClass('checkcontainer').append('<span class="checkmark"></span>');
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
